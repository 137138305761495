

























import Ueditor from "@/components/Ueditor/index.vue";
import {Component, Ref, Vue} from "vue-property-decorator";
import api from "@/api";
import {
  AttachmentHostType, CustomFormType,
  DataDictionaryDtoPagedResultDto,
  FundDto, FundProjectApplyCreateOrUpdateDto, FundProjectApplyDto, FundProjectDto,
  FundProjectModifyApplyCreateOrUpdateDto,
  FundProjectModifyApplyDto,
} from "@/api/appService";
import {ElForm} from "element-ui/types/form";
import AbSelect from "@/components/AbSelect/index.vue";
import MultipleUploadFile from "@/components/MultipleUploadFile/index.vue";
import SimpleUploadImage from "@/components/SimpleUploadImage/index.vue";
import CustomFormEdit from "@/components/CustomForm/Edit/index.vue";

@Component({
  components: {
    CustomFormEdit
  },
})
export default class EditApplyFundProject extends Vue {
  dataId!: number;
  btnDisabled = false;
  defaultData: FundProjectModifyApplyDto = {};

  get getData(): Promise<FundProjectModifyApplyDto> {
    if (this.dataId > 0) return api.fundProject.get({id: this.dataId});
    else return new Promise<FundProjectModifyApplyDto>(resolve => this.defaultData);
  }

  get hostType() {
    return CustomFormType.FundProject;
  }

  get hostTypeFile() {
    return AttachmentHostType.FundProjectModifyApply;
  }

  created() {
    if (this.$route.params.id) {
      this.dataId = Number(this.$route.params.id);
    }
  }


  handleSave(): void {
    this.btnDisabled = true;
    (this.$refs.customForm as any).validate(async (valid: boolean) => {
      if (valid) {
        const data = (
          this.$refs.customForm as any
        ).getRequestData() as FundProjectModifyApplyCreateOrUpdateDto;
        let fn = api.fundProjectModifyApply.create;
        data.extensionData = '';
        data.projectId = this.dataId;
        data.id = 0;
        fn({body: data}).then((res) => {
          this.$message.success("更新成功");
          this.$router.push({
            path: "/fundProject/fundProjectModifyApply",
          });
        });
      } else {
        this.$message.error("请检查表单");
        this.btnDisabled = false;
      }
    });
  }

  cancel() {
    this.$router.back();
  }

}
